import { Page404Component } from './authentication/page404/page404.component';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { LandingComponent } from './landing/landing.component';
import { LoggedInAuthGuard } from './core/guard/loggedIn.guard';
const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    loadChildren: () =>
    import('./landing/landing.module').then(
      (m) => m.LandingModule
    ),
},
  {
    path: 'admin',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'home',
        loadChildren: () =>
          import('./home/home.module').then(
            (m) => m.HomeModule
          ),
      },
      {
        path: 'advance-table',
        loadChildren: () =>
          import('./advance-table/advance-table.module').then(
            (m) => m.AdvanceTableModule
          ),
      },
      {
        path: 'charger-updates',
        loadChildren: () =>
          import('./charger-updates/charger-updates.module').then(
            (m) => m.ChargerUpdatesModule
          ),
      },
      {
        path: 'logs',
        loadChildren: () =>
          import('./logs/logs.module').then(
            (m) => m.LogsModule
          ),
      },
      {
        path: 'mobile/version',
        loadChildren: () =>
          import('./mobile/mobile.module').then(
            (m) => m.MobileModule
          ),
      },
      {
        path: 'inactive-user-delete',
        loadChildren: () =>
          import('./inactive-user-delete/inactive-user-delete.module').then(
            (m) => m.InactiveUserDeleteModule
          ),
      }
    ],
  },

  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  { path: '**', component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
