// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'http://localhost:4200',
  loginUrl: 'https://api.admin.voltie.hu/login/frontend',
  fbFrontendLoginEmai: "voltie.frontend@voltie.eu",
  fbFrontendLoginSecret: "a47d8e9e-1359-4762-9d86-7c432b241603",
  firebaseConfig: {
    apiKey: "AIzaSyBtNQhnIcWxr9ny4pklYxkXYKTl-RlO8_M",
    authDomain: "voltie-charger.firebaseapp.com",
    databaseURL: "https://voltie-charger-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "voltie-charger",
    storageBucket: "voltie-charger.appspot.com",
    messagingSenderId: "130173012234",
    appId: "1:130173012234:web:834e34c16349dbc0dd52f3",
    measurementId: "G-FP0Q1FY5VN"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
