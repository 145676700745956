<html>
<body style="background-color: white">
  <!-- <div style="background: black; height: 100%; width: 100%;">
    <div style="background: black; width: 100%; display: flex; justify-content: center; ">
      <img src="/assets/images/kijelzo.png">
    </div>
    <div style="background: black; height: 100%; width: 100%;">
      <h1 style="font-size: 55px; font-weight: bold; font-family: SF Compact Display; background: -webkit-linear-gradient( left, #8ECED0,#5490CC);
      -webkit-background-clip: text; -webkit-text-fill-color: transparent; text-align: center">HAMAROSAN...</h1>
    </div>
  </div> -->
</body>
</html>

