import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.sass']
})
export class LandingComponent implements OnInit  {

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.goToUrl();
  }

  goToUrl(): void {
    this.document.location.href = 'https://voltie.eu';
  }

  ngOnInit(){

  }

}
